@import '~@holberg/ui-kit/src/scss/core.scss';
@import 'components/NavigationHeader/NavigationHeader.module.scss';

$snack-bar-height: 40px;
$content-height: calc(100% - 166px);
$warning-msg-height: 24px;

.wrapper {
  @extend %transition-all;

  height: calc(100vh - #{$nav-header-height});
}

.snack-wrapper {
  @extend %transition-all;

  height: calc(100vh - #{$nav-header-height} - #{$snack-bar-height});
}

.study-details-page {
  width: 100%;
  background: $N100;
  height: 100%;

  .content {
    height: $content-height;
    margin-top: 4px;

    &.has-warning-msg {
      height: calc(#{$content-height} - #{$warning-msg-height});
    }
  }

  .header {
    padding-right: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tabs {
      display: flex;
    }

    .tab {
      text-decoration: none;
      letter-spacing: 1px;
      text-transform: uppercase;

      &:first-child {
        margin-left: 8px;

        .curve-left {
          left: 0;
          bottom: -8px;
          transform: rotate(90deg);
        }
      }
    }

    .switch-wrap {
      align-items: center;
      display: flex;
    }

    .switch-text {
      @extend %font-text-medium;

      color: $N900;
      margin-right: 10px;
    }

    .expand-button {
      padding: 2px;

      path {
        fill: $N800;
      }
    }
  }

  .modal {
    .content {
      max-height: 720px;
      max-width: 900px;
      padding: 24px;
    }

    .wrap {
      margin-top: 20px;
      max-height: 620px;
      overflow-y: auto;
    }
  }
}
