@import '~@holberg/ui-kit/src/scss/core.scss';
$study-details-header-height: 126px;
$warning-msg-height: 24px;

.study-details-header {
  height: $study-details-header-height;
  width: 100%;
  background: $N100;

  .warning-msg-content {
    align-items: center;
    display: flex;
    margin: 0;

    .btn {
      color: inherit;
      font-size: inherit;
      margin-left: 4px;
      padding: 0;

      &:hover {
        color: inherit;
      }
    }
  }

  .row-title {
    display: inline;
  }

  .row {
    margin-right: 20px;
  }

  .retry-container {
    color: inherit;
    padding: 5px;

    &.retry {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }

  .action-container {
    display: flex;
    align-items: flex-start;

    .edit-button {
      margin-left: 8px;
    }
  }

  .badge {
    margin-right: 16px;
  }

  &.has-warning-msg {
    height: calc(#{$study-details-header-height} + #{$warning-msg-height});
  }
}

@media (max-width: 1040px) {
  .study-details-header {
    .row-title {
      display: none;
    }
  }
}