@import '~@holberg/ui-kit/src/scss/core.scss';
@import 'components/NavigationHeader/NavigationHeader.module.scss';

.wrapper {
  @extend %transition-all;

  height: calc(100vh - #{$nav-header-height});
}

.snackWrapper {
  @extend %transition-all;

  height: calc(100vh - 104px);
}

.patients-page {
  height: 100%;

  .content {
    padding: 24px 32px 0;
    height: 100%;
  }

  .table-wrapper {
    height: calc(100% - 40px);
  }

  .table {
    width: calc(100vw - #{$nav-header-height});
    height: 100%;
    margin-top: 20px;
    outline: none;

    &:focus {
      border: none;
    }
  }

  .table-loading {
    height: calc(100vh - 218px);
  }

  .footer {
    pointer-events: all;
  }
}
