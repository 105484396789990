@import '~@holberg/ui-kit/src/scss/core.scss';

.unclassified-examples-container {
  $width: 240px;
  border: 2px solid transparent;

  height: 100%;
  overflow: hidden;

  .header {
    padding: 24px 24px 8px 0;
    position: relative;

    .title {
      @extend %font-text-medium;

      color: $N800;
      margin: 0;
      text-align: center;
    }

    .btn-collapse {
      @extend %transition-all;

      opacity: 0;
      padding: 2px;
      position: absolute;
      right: 8px;
      top: 8px;
      visibility: hidden;
    }
  }

  .list-wrap {
    height: 100%;
    overflow-y: auto;
    width: $width;
  }

  .list {
    padding: 0 8px;
    margin-bottom: 72px;

    &-item {
      margin-bottom: 1px;
    }

    &-stretch-height {
      height: 100%;
      margin-bottom: 0;
    }
  }

  .empty-list {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;

    &-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 52px;

      .general-error {
        margin-top: -8px;
      }
    }
  }

  &:hover .header .btn-collapse {
    opacity: 1;

    visibility: visible;
  }
}
