.modal.report-preview-modal {
  .content {
    max-height: 720px;
    max-width: 900px;
  }

  .wrap {
    min-width: 700px;
    margin-top: 20px;
    max-height: 620px;
    overflow-y: auto;
  }
}
